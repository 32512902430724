import { FieldValue } from 'firebase/firestore'
import React from 'react'
import styled from 'styled-components'

const SponsorBankRow = (props) => {

    return (
        <Container className="sponsor-row">
            <p className="title">{props.bank.name}</p>
            <p className="postcode">{props.bank.postcode.toUpperCase()}</p>
            <p className="distance">{Math.round(props.distance * 100) / 100} miles</p>
            <button className='ad-buy-btn' onClick={() => {props.updateFunction(props.bank.name, (props.bank.adCost ? props.bank.adCost : 40))}}><span className="bank-ad-cost">{props.bank.adCost ? props.bank.adCost : "£40"}</span> <span className="bank-ad-cost-subtext">per 4 weeks</span> <span className="ad-add">Add to Cart</span></button>
        </Container>
    )
}

export default SponsorBankRow

const Container = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;

    .title {
        width: 100%;
        max-width: 360px;
        padding-left: 12px;
        font-size: 14px;
    }

    .distance {
        width: 100%;
        max-width: 100px;
        font-size: 12px;
    }

    button {
        border: 0;
        background: 0;
        cursor: pointer;
    }

    .bank-ad-cost-subtext {
        font-size: 10px;
        margin: 0 8px;
    }

    .ad-add {
        padding-right: 12px;
        :hover {
            text-decoration: underline;
        }
    }

    .postcode {
        width: 68px;
    }

    @media (max-width: 880px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        .postcode,
        .ad-buy-btn {
            margin: auto 0 auto auto;
            text-align: right;
        }

        .postcode {
            padding-right: 12px;
        }

        .distance {
            padding-left: 12px;
        }
    }
`