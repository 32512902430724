import React from "react";
import styled from 'styled-components';
import { StickyShareButtons } from "sharethis-reactjs";

const SocialButtons = () => {
    return (
        <Container>
            <StickyShareButtons
                config={{
                    alignment: 'left',  // alignment of buttons (left, center, right)
                    color: 'social',      // set the color of buttons (social, white)
                    enabled: true,        // show/hide buttons (true, false)
                    font_size: 16,        // font size for the buttons
                    labels: 'cta',        // button labels (cta, counts, null)
                    language: 'en',       // which language to use (see LANGUAGES)
                    networks: [           // which networks to include (see SHARING NETWORKS)
                    'whatsapp',
                    'email',
                    'messenger',
                    'facebook',
                    'twitter',
                    'sms'
                    ],
                    padding: 12,          // padding within buttons (INTEGER)
                    radius: 4,            // the corner radius on each button (INTEGER)
                    show_total: false,
                    show_mobile: true,
                    size: 40,             // the size of each button (INTEGER)
                }}
            />
        </Container>
    )
}

export default SocialButtons

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;

    position: fixed;
    top: 120px;
    left: 0;
    z-index: 5;

    button {
        line-height: 0;
        height: 48px;
        margin: 0;
        padding: 0;
    }

    svg {
        line-height: 0;
    }
`

const CopyLinkButton = styled.a`
    width: 48px;
    height: 48px;
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        width: 32px;
        fill: white;
        height: 32px;
    }
`