import React from 'react'
import styled from 'styled-components'
import Header from '../../components/Header'

const Campaign = () => {

    return (
        <PageContainer>
            <Header />
            <PageContent>
                <Title>Terms and Conditions</Title>
                <Subtitle>for an advertising campaign</Subtitle>
                <p>Terms and conditions here...</p>
            </PageContent>
        </PageContainer>
    )
}

export default Campaign

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    p {
        margin: 4px 32px;
    }
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    padding-left: 18px;
`

const Subtitle = styled.h2`
    font-size: 36px;
    font-weight: normal;
    padding-left: 18px;
    font-style: italic;
    margin: 0 0 32px 0;
`