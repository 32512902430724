import React from 'react'
import styled from 'styled-components'
import Header from '../components/Header'
import { PageContainer } from '../components/StyledItems'

const NearestBanks = () => {
    return (
        <PageContainer>
            <Header />
            
        </PageContainer>
    )
}

export default NearestBanks