import React from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import { db, storage, auth } from '../util/firebaseApp';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore'

const BankHitComponent = ({hit}) => {
    const navigate = useNavigate();

    const deleteBank = async (bankId, bankName) => {
        if (window.confirm("Are you sure you want to remove " + bankName)) {
            const docRef = doc(db, "banks", bankId);
            await deleteDoc(docRef)
            .then(alert("Delete Successful"));
        }
    }

    return (
        <BankListItem key={hit.name}>
            <BankName onClick={() => {navigate(`/bank?bankId=${hit.objectID}`)}}>{hit.name}</BankName>
            <BankEditButton href={`/edit-bank?bankId=${hit.objectID}`}>Edit</BankEditButton>
            <BankDeleteButton onClick={() => { deleteBank(hit.objectID, hit.name); }}>Delete</BankDeleteButton>
        </BankListItem>
    )
}

export default BankHitComponent

const BankListItem = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
`

const BankName = styled.h2`
    font-size: 24px;
    color: black;
`

const BankEditButton = styled.a`
    color: blue;
    font-weight: normal;
    margin: 24px 12px;
    font-size: 18px;
    text-decoration: none;
    display: block;
`

const BankDeleteButton = styled.button`
    color: blue;
    font-weight: normal;
    margin: 24px 6px;
    font-size: 18px;
    text-decoration: none;
    padding: 0;
    background: none;
    border: 0;
`