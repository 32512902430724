import React, { useState } from 'react'
import { auth } from '../util/firebaseApp';
import { signInWithEmailAndPassword } from 'firebase/auth'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';

const Login = () => {
    const [formStatus, setFormStatus] = useState('Send');
    const navigate = useNavigate();

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { password, email } = e.target.elements;

        let loginForm = {
            password: password.value,
            email: email.value,
        }

        signInWithEmailAndPassword(auth, loginForm.email, loginForm.password)
            .then((userCredential) => {
                //Signed in
                const user = userCredential.user;
                console.log("Logged in successfully!!");
                console.log("User: " + user);
                navigate("/account");
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
        })
    }

    return (
        <Container>
            <Header />
            <Form onSubmit={onSubmit} className="sponsor-form" noValidate>
                <div className="form-field-container">
                    <label className="form-label" htmlFor="email">Email</label>
                    <input className="form-control" type="text" id="email" required />
                </div>
                <div className="form-field-container">
                    <label className="form-label" htmlFor="password">Password</label>
                    <input className="form-control" type="password" id="password" required />
                </div>
                <button className="btn btn-danger" type="submit">
                    {formStatus}
                </button>
            </Form>
        </Container>
    )
}

export default Login;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 540px;
    width: 100%;

    .form-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 21px;
    }

    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 18px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        font-weight: normal;
        margin-top: 18px;
        margin-bottom: 22px;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`