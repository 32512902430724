export const menuItems = [{
	"id": "home",
	"label": "HOME",
	"link": "/",
	"active": false
}, {
	"id": "about",
	"label": "ABOUT US",
	"link": "https://www.fundraiserecycle.co.uk/about-us",
	"active": false
}, {
	"id": "book",
	"label": "BOOK A COLLECTION",
	"link": "/book-collection",
	"active": false
}, {
	"id": "contact",
	"label": "CONTACT",
	"link": "https://www.fundraiserecycle.co.uk/contact",
	"active": false
}];