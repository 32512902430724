import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { db, storage } from '../util/firebaseApp'
import { doc, setDoc, getDocs, collection } from 'firebase/firestore'
import emailjs from 'emailjs-com'
import CollectionDropdownOption from '../components/CollectionDropdownOption'

/*

TODO: FIX DROPDOWN SELECTION
ADD OPTION BASED ON QUERY PARAMETER

*/

const BookCollection = () => {
    const [formStatus, setFormStatus] = useState('Send');
    const [banks, setBanks] = useState({});
    const form = useRef();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [bankId, setBankId] = useState("");

    const getBanks = async () => {
        const bankList = [];
        const snapshot = await getDocs(collection(db, 'banks'));
        snapshot.forEach((doc) => {
            bankList.push(doc);
        })
        setBanks(bankList);
    }

    useEffect(() => {
        setBankId(searchParams.get('bankId') ? searchParams.get('bankId') : "fundraise-recycle");
        getBanks();
    }, []);

    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { firstName, lastName, email, phone, address, date, cause } = e.target.elements;
        let collectionForm = {
            firstName: firstName.value,
            lastName: lastName.value,
            email: email.value,
            phone: phone.value, 
            date: date.value,
            cause: cause.value,
            address: address.value,
        }

        const newCollection = doc(collection(db, "collectionBookings"));
        await setDoc(newCollection, collectionForm);

        emailjs.sendForm('service_carxj5m', 'template_rkasfsi', e.target, 'qNipy6J_jiSb8K7-E')
            .then((result) => {
                console.log(result.text);
                console.log("Submission Successful");
                setFormStatus("Success! Thank you");
                navigate(`/bank?bankId=${cause.value}`);
            }, (error) => {
                console.log(error.text);
                setFormStatus("Something went wrong, please try again.");
        });
        
        navigate(History.back());
    }

    return (
        <PageContainer>
            <Header />
            <PageContent>
                <Title>Book a Collection</Title>
                <Form ref={form} onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-row">
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="firstName">First Name</label>
                            <input className="form-control" name="firstName" type="text" id="firstName" required />
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="lastName">Last Name</label>
                            <input className="form-control" name="lastName" type="text" id="lastName" required />
                        </div>
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="email">Email Address</label>
                        <input className="form-control" name="email" type="text" id="email" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="phone">Contact Number</label>
                        <input className="form-control" name="phone" type="text" id="phone" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="address">Your Address</label>
                        <textarea className="form-control" name="address" id="address" />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="date">Collection Date</label>
                        <input className="form-control" name="date" type="date" id="date" />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="cause">Who is your donation on behalf of?</label>
                        <select className="form-control" name="cause" id="cause">
                            {banks.length > 0 ? banks.map((bank) => 
                                <option id={"dropdown-" + bank.id} key={bank.id} value={bank.id} name={bank.id} selected={bankId === bank.id}>{bank.data().name}</option>
                            ) : ""}
                        </select>
                    </div>
                    <button className="btn btn-danger" type="submit">
                        {formStatus}
                    </button>
                </Form>
            </PageContent>
            <Footer />
        </PageContainer>
    )
}

export default BookCollection

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1060px) {
        background: rgb(233, 232, 234);
    }
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    padding-left: 18px;
`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 24px;
    
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input,
    textarea,
    select {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        margin-top: 24px;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`