import styled from 'styled-components'

export const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 1060px) {
        background: rgb(233, 232, 234);
    }
`

export const HeroSection = styled.div`
    background: #E9E8EA;
    position: relative;
    width: 100%;
    z-index: 0;

    ::after {
        background: #EDB524;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 40vw;
        z-index: -1;
        content: "";
    }

    @media (max-width: 1060px) {
        ::after {
            left: 0;
            top: 0;
            height: 40%;
            width: 100vw;
            z-index: -1;
            content: "";
        }
    }
`

export const VideoStatSection = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 60px;
    padding: 100px 0;

    @media (max-width: 1090px) {
        gap: 20px;
    }

    @media (max-width: 1060px) {
        flex-direction: column;
        padding-bottom: 0;
        padding-top: 20px;
    }
`

export const VideoContainer = styled.div`
    width: 40vw;
    overflow: hidden;

    figure {
        margin: 0;
    }

    @media (max-width: 1360px) {
        width: 50vw;
    }

    video {
        width: 100%;
        box-shadow: 1px 0px 4px 2px rgba(0,0,0,0.75);
    }

    @media (max-width: 1060px) {
        width: 80vw;
    }
`

export const StatContainer = styled.div`
`

export const Stats = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

export const QuantityStat = styled.p`
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    background: #EDB524;
    color: white;
    border-radius: 60px 0 0 60px;
    padding: 20px;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    span {
        font-weight: 800;
        color: white;
    }

    @media (max-width: 1060px) {
        font-size: 1rem;
    }

    @media (max-width: 440px) {
        font-size: 0.8rem;
    }
`

export const RaisedStat = styled.p`
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    background: #BE3258;
    color: white;
    padding: 20px;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    margin: 0 12px;
    span {
        font-weight: 800;
        color: white;
    }

    @media (max-width: 1060px) {
        font-size: 1rem;
    }

    @media (max-width: 440px) {
        font-size: 0.8rem;
    }
`

export const TreesStat = styled.div`
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    background: #174B64;
    color: white;
    padding: 20px;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    border-radius: 0 60px 60px 0;
    span {
        font-weight: 800;
        color: white;
    }

    @media (max-width: 1060px) {
        font-size: 1rem;
    }

    @media (max-width: 440px) {
        font-size: 0.8rem;
    }

    a {
        color: white;
        text-decoration: none;
        font-weight: bold;
        line-height: 2;
    }
`

export const HeroContent = styled.div`
    margin-top: 160px;
`

export const Title = styled.h1`
    font-weight: 800;
    line-height: 1;
    font-size: 52px;
    max-width: 280px;
    color: #003955;
    margin: 0;

    @media (max-width: 1060px) {
        text-align: center;
        max-width: none;
        margin-bottom: 24px;
    }

    @media (max-width: 684px) {
        max-width: 100%;
        font-size: 8vw;
    }
`

export const GoalText = styled.p`
    font-weight: 300;
    font-size: 24px;
    color: #003955;
    max-width: 380px;
    margin: 0;

    @media (max-width: 1060px) {
        text-align: center;
        max-width: 80vw;
        margin: 0 auto;
        font-size: 3vw;
    }
`

export const CTAStrip = styled.div`
    background: #BE3258;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100vw;
    margin: 0 auto;
    margin-top: 0;
    padding-bottom: 24px;

    @media (max-width: 1060px) {
        margin-top: 24px;
    }
`

export const CTAContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;   
    position: relative;
    height: 180px;
    cursor: pointer;

    :nth-child(3) {
        @media (max-width: 540px) {
        }
    }

    :nth-child(2)::before,
    :nth-child(2)::after {
        width: 2px;
        height: 140px;
        background: white;
        content: "";
        position: absolute;

        @media (max-width: 1060px) {
            height: 80px;
            width: 1px;
        }
    }

    :nth-child(2)::before {
        left: 0;
        top: 24px;
    }

    :nth-child(2)::after {
        right: 0;
        top: 24px;
    }

    @media (max-width: 1060px) {
        height: auto;
        margin-top: 24px;
    }

    @media (max-width: 530px) {
        //justify-content: center;
    }
`

export const CTAIcon = styled.img`
    margin: 0 auto;
    width: 64px;
    height: auto;
    fill: white;

    @media (max-width: 680px) {
        width: 48px;
        height: auto;
    }
`

export const CTAText = styled.p`
    font-size: 28px;
    color: white;
    text-align: center;
    white-space: pre-wrap;
    margin-bottom: 0;

    @media (max-width: 1060px) {
        font-size: 1.2rem;
    }

    @media (max-width: 600px) {
        font-size: 1rem;
    }
`

export const SponsorStrip = styled.h2`
    width: 100%;
    background: #BE3258;
    font-size: 42px;
    font-weight: 800;
    color: white;
    text-align: center;
    margin: auto;
    grid-column-start: 1;
    grid-column-end: 3;

    @media (max-width: 1060px) {
        font-size: 2rem;
    }
    
    @media (max-width: 570px) {
        font-size: 6.5vw;
    }
`

export const AdvertStrip = styled.div`
    background: #BE3258;
    width: 100%;
    padding: 48px 0;
`

export const AdvertCardContainer = styled.div`
    max-width: 1080px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: 2ch;
    margin: 0 auto;
`

export const AdvertCard = styled.a`
    box-shadow: 5px 5px 5px 0px rgba(0,0,0,0.75);
    width: 240px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background: white;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    p {
        color: rgb(0, 57, 85);
        font-size: 2.5vw;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        text-decoration: none;

        @media (min-width: 1100px) {
            font-size: 26px;
        }
    }

    img {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
        object-fit: cover;
        object-position: center;
    }

    @media (max-width: 1060px) {
        width: 22vw;
        height: 14vw;
    }
`

export const MapStrip = styled.div`
    width: 100%;
    height: 450px;
    overflow: hidden;

    position: relative;

    iframe {
        border: 0;
    }
`

export const MapInfoBox = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ExitPopup = styled.a`
    color: white;
    font-size: 36px;
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 101;
    cursor: pointer;
`

export const MapInfoContent = styled.div`
    margin: auto;
    border-radius: 60px;
    box-shadow: 0px 0px 26px -1px rgba(0,0,0,0.75);
    background: white;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 40px;

    h2 {
        text-align: center;
    }

    .border-above {
        border-top: 3px solid #003955;
    }

    h3 {
        position: relative;
        text-align: center;
        margin: 0;
        padding-top: 8px;
    }

    p {
        text-align: center;
    }
`