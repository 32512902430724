import React, { useMemo, useRef, useState, useEffect } from "react";
import styled from 'styled-components';
import Header from '../components/Header';
import { db, storage } from '../util/firebaseApp';
import { doc, query, setDoc, getDocs, collection, orderBy, startAt, endAt } from 'firebase/firestore';
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
  } from "react-geocode";
import geohash from 'ngeohash';
import { geohashQueryBounds, distanceBetween, geohashForLocation } from "geofire-common";
import SponsorBankRow from "../components/sponsorCalculator/SponsorBankRow";
import PaypalButtonWrapper from "../components/sponsorCalculator/PaypalButtonWrapper";
import emailjs from 'emailjs-com';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import GoogleMapReact from 'google-map-react';
import Map from "../components/sponsorCalculator/Map";
import { MarkerF } from "@react-google-maps/api";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import CheckoutForm from "../components/CheckoutForm";
import getStripe from "../util/getStripe";

const GeoQueryTest = () => {
    var fromHash = 0;
    var fromLat = 0;
    var fromLng = 0;

    const currency = "GBP";
    const sponsorTestForm = useRef();
    const [formStatus, setFormStatus] = useState("Search");
    const [searchResults, setResults] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [paymentReady, setPaymentReady] = useState(false);
    const [fromData, setFromData] = useState({
        "lat": 54.7678734,
        "lng": -1.3665964
    });
    const [adLength, setLength] = useState(0);
    const [tcUrl, setTCUrl] = useState('');
    const [advertCost, setCost] = useState(0);
    const [paypalButtonStatus, setPaypalButtonStatus] = useState(false);
    const [advertSelection, setSelection] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [mapMarkers, setMarkers] = useState([]);
    const [center, setCenter] = useState({ lat: 54.7678734, lng: -1.3665964 });
    // Default to 10 mile radius
    const [searchRadius, setSearchRadius] = useState(16000);

    const updateSponsorshipOption = (id, quantity) => {
        let tempSelection = advertSelection;
        tempSelection[id] = parseInt(quantity);
        setSelection(tempSelection);

        console.log("Updated Selection");
        console.log(advertSelection);

        var numBanksToAdvertise = 0;
        var advertTotalCost = 0;
        for (const bank in tempSelection) {
            if (tempSelection[bank] > 0) {
                numBanksToAdvertise++;
                advertTotalCost += tempSelection[bank];
            }
        }

        if (numBanksToAdvertise > 10) {
            advertTotalCost *= 0.9;
        }

        setCost((advertTotalCost / 4) * adLength);
    }

    const onSubmitDetails = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { name, email, phone, link, image } = e.target.elements;

        if (!image.files[0]) {
            alert("An image has not been uploaded");
            return;
        }

        const imageRef = ref(storage, `sponsor-images/${image.files[0].name}`);
        let sponForm = {
            name: name.value,
            email: email.value,
            phone: phone.value, 
            link: link.value,
            image: imageRef.fullPath
        }

        uploadBytes(imageRef, image.files[0]).then((snapshot) => {
            console.log("Uploaded image");

            getDownloadURL(ref(storage, `sponsor-images/${image.files[0].name}`))
            .then((url) => {
                var templateParams = {
                    name: name.value,
                    email: email.value,
                    phone: phone.value,
                    link: link.value,
                    image: url
                };
    
                emailjs.send('service_carxj5m', 'template_a0czwt2', templateParams, 'qNipy6J_jiSb8K7-E')
                .then((result) => {
                    console.log(e.target.elements);
                    console.log(result.text);
                    console.log("Submission Successful");
                    setFormStatus("Success! Thank you. Please make your payment now by clicking the 'checkout' button.");
                    setPaypalButtonStatus(true);
                }, (error) => {
                    console.log(error.text);
                    setFormStatus("Something went wrong, please try again.");
            });
            })
        })

        await setDoc(doc(db, 'sponsorRequests', phone.value), sponForm);
    }

    const sortGeoRecords = (r, arrayToAddTo, markerArrayToAddTo) => {
        r.forEach((doc) => {
            const lat = doc.get("latitude");
            const lng = doc.get("longitude");
            const distanceInKm = distanceBetween([parseFloat(lat), parseFloat(lng)], [parseFloat(fromData["lat"]), parseFloat(fromData["lng"])]);
            if ((distanceInKm * 1000) <= searchRadius) {
                arrayToAddTo.push(doc);
                markerArrayToAddTo.push({ lat: parseFloat(lat), lng: parseFloat(lng) });
            }
        });
    }

    const getRecordsByGeo = async (q, arrayToAddTo, markerArrayToAddTo) => {
        const querySnapshot = await getDocs(q);
        sortGeoRecords(querySnapshot, arrayToAddTo, markerArrayToAddTo);
        setResults(arrayToAddTo);
        setMarkers(markerArrayToAddTo);
    }

    useEffect(() => {
        setCenter({lat: fromData["lat"], lng: fromData["lng"]});
        let coords = [fromData["lat"], fromData["lng"]];

        if (formSubmitted) {
            const bounds = geohashQueryBounds(coords, searchRadius);
            const tempResultsArray = [];
            const tempMarkerArray = [];
            for (const b of bounds) {
                const q = query(
                    collection(db, 'banks'),
                    orderBy("geohash"),
                    startAt(b[0]),
                    endAt(b[1])
                );
                getRecordsByGeo(q, tempResultsArray, tempMarkerArray)
                .then(() => {
                    setResults(tempResultsArray);
                    setMarkers(tempMarkerArray);
                })
            }
            setFormSubmitted(false);
        }
    }, [fromData]);

    const GenerateGeoHash = (postcode) => {
        var hashData = {};
        setKey("AIzaSyCuEx43ft9p0r4ZgsmheRMuyQHlgy2Yv9w");
        setLanguage("en");
        setRegion("gb");
        fromAddress(postcode).then(
            (response) => {
                const {lat, lng} = response.results[0].geometry.location;
                hashData["lat"] = lat;
                hashData["lng"] = lng;
                hashData["geoHash"] = geohash.encode(lat, lng);
                setFromData(hashData);
            },
            (error) => {
                console.error(error);
            }
        )
    }

    const onSubmit = async (e) => {
        setFormSubmitted(true);
        e.preventDefault();
        const { postcode, radius, advertLength } = e.target.elements;
        var gHash = GenerateGeoHash(postcode.value);
        setSearchRadius(radius.value);
        setLength(advertLength.value)
    }

    const initialOptions = {
        "client-id": "AZvNZToUbRxEvikVXnMXYXK7NsCOcKYH0Bzq47VJoskttP1-50Z-EOrTcWVhsbZr-obGFGzgUHaLToV5",
        components: "buttons",
        currency: "GBP",
    };

    async function handleCheckout(adQuantity) {
        const stripe = await getStripe();
        if (stripe) {
          const { error } = await stripe.redirectToCheckout({
            lineItems: [
              {
                price: 'price_1PazJ2RtiEQDWo97nWifiwTI',
                quantity: adQuantity,
              },
            ],
            mode: "payment", // here you can specify the mode as either `payment` or `subscription` for one time payments or recurring payments respectively
            successUrl: `http://online.fundraiserecycleltd.co.uk/success`,
            cancelUrl: `http://online.fundraiserecycleltd.co.uk/cancel`,
          });
          console.warn(error.message);
        }
      }

    return (
        <Container>
            <Header />
            <ContentContainer>
                <p>Advertising on more than 10 clothes banks? <strong>Save 10%</strong></p>
                <p>Total Cost: £{advertCost}</p>
            
                {
                !paymentReady ?
                <Form ref={sponsorTestForm} onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-fields-container">
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="postcode">Postcode</label>
                            <input className="form-control" type="text" id="postcode" required />
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="radius">Search Radius</label>
                            <select id="radius" name="radius">
                                <option value={1600}>1 Mile</option>
                                <option value={3200}>2 Miles</option>
                                <option value={6400}>5 Miles</option>
                                <option value={16000}>10 Miles</option>
                            </select>
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="advertLength">Advert Duration</label>
                            <select id="advertLength" name="advertLength">
                                <option value={4}>4 Weeks</option>
                                <option value={8}>8 Weeks</option>
                                <option value={12}>12 Weeks</option>
                                <option value={16}>16 Weeks</option>
                                <option value={20}>20 Weeks</option>
                            </select>
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="startDate">Start Date</label>
                            <DatePicker id="startDate"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    dateFormat="dd/MM/yyyy" />
                        </div>
                        {
                        !paymentReady ?
                            <button className="btn btn-danger" type="submit">
                                {formStatus}
                            </button> :
                        ""
                        }
                    </div>
                    {
                        advertCost > 0 ?
                        <button className="checkout-btn" onClick={() => {setPaymentReady(true)}}>Continue</button> : ""
                    }
                </Form> : ""
                }
                {
                    paymentReady ?
                    <Form onSubmit={onSubmitDetails} className="sponsor-form" noValidate>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="name">Name</label>
                            <input className="form-control" type="text" id="name" name="name" required />
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="email">Email Address</label>
                            <input className="form-control" type="text" id="email" name="email" required />
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="phone">Phone Number</label>
                            <input className="form-control" type="text" id="phone" name="phone" required />
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="link">Link for your Advert</label>
                            <input className="form-control" type="text" name="link" id="link" />
                        </div>
                        <div className="form-field-container">
                            <label className="form-label" htmlFor="image">Advert Image</label>
                            <input className="form-control" type="file" id="image" />
                        </div>
                        {
                            !paymentReady ?
                            <button className="btn btn-danger" type="submit">
                                {formStatus}
                            </button> :
                            ""
                        }
                        
                        <button onClick={() => {
                            handleCheckout(advertCost / 40)
                        }} type="submit">Pay Now</button>
                        
                    </Form> :
                    ""
                }
                <Map center={center}>
                    <MarkerF 
                        className="map-marker"
                        position={center}
                        icon="https://fundraise-recycle.s3.us-west-002.backblazeb2.com/home-icn.png"
                    />
                    {
                        mapMarkers.map((marker) => 
                            <MarkerF 
                                position={{lat: marker.lat, lng: marker.lng}} 
                                key={marker.lat}
                                icon="http://maps.google.com/mapfiles/ms/micons/red-dot.png"
                                style={{
                                    backgroundColor: "#0000ff",
                                    fillColor: "#0000ff",
                                    strokeColor: "0000ff",
                                }}
                            />
                        )
                    }
                </Map>
                {
                    searchResults.length > 0 && !paymentReady ?
                        <SearchResultsContainer>
                        <p>There are {searchResults.length} clothes bank(s) within your search area.</p>
                        {searchResults.map((bank) =>
                            <SponsorBankRow className="sponsor-row" key={bank.id} updateFunction={updateSponsorshipOption} id={bank.id} bank={bank.data()} name={bank.data().name} distance={distanceBetween([parseFloat(bank.data().latitude), parseFloat(bank.data().longitude)], [fromData.lat, fromData.lng]) / 1.6} />
                        )}
                        </SearchResultsContainer> :
                        ""
                }
            </ContentContainer>
        </Container>
    )
}

export default GeoQueryTest;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    >p {
        margin-bottom: 0;
        text-align: center;
    }
`

const SearchResultsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;

    .sponsor-row:nth-child(even) {
        background: rgba(1, 1, 1, 0.1);
    }
`

const Form = styled.form`
    display: flex;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
    padding: 24px;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .form-fields-container {
        display: flex;
        flex-flow: row wrap;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 28px;
    }

    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .react-datepicker button {
        background: none;
    }

    input,
    select {
        box-sizing: border-box;
        width: 164px;
        padding: 8px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        border-radius: 0 24px 0 24px;
        padding: 8px 16px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        font-weight: normal;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }

    .checkout-btn {
        margin: 24px auto;
    }
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    width: 100%;

    >p {
        text-align: center;
    }
`

const PayButtonsContainer = styled.div`

`

const MapContainer = styled.div`
    height: 450px;
    width: 500px;
`

const MapMarker = styled.div`

`