import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { db, storage, auth } from '../util/firebaseApp';
import { doc, setDoc, Collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, uploadBytesResumable } from 'firebase/storage';
import { onAuthStateChanged, createUserWithEmailAndPassword } from 'firebase/auth';
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
  } from "react-geocode";
import geohash from 'ngeohash';
import { useNavigate } from 'react-router-dom'

const AddBank = () => {
    const [formStatus, setFormStatus] = useState("Send");
    const [userStatus, setUserStatus] = useState(false);
    const [userID, setUserID] = useState("");
    const form = useRef();
    const navigate = useNavigate();
    const [progressPercent, setProgressPercent] = useState(0);

    const looper = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    
    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUserID(user.uid);
          setUserStatus(true);
        } else {
          setUserStatus(false);
        }
    });

    /*useEffect(() => {
        alert(progressPercent);
    }, [progressPercent])*/

    const onSubmit = async (e) => {
        console.log("Sending");
        e.preventDefault();
        //setFormStatus("Submitting..");

        const { name, 
            email, 
            quantity, 
            password, 
            image, 
            website, 
            postcode,
            twitter, 
            mapEmbed, 
            facebook,
            instagram, 
            bankId, 
            trees, 
            goal, 
            benches, 
            raised,
            sponsor1name,
            sponsor1link,
            sponsor1image,
            sponsor2name,
            sponsor2link,
            sponsor2image,
            sponsor3name,
            sponsor3link,
            sponsor3image,
            sponsor4name,
            sponsor4link,
            sponsor4image,
            sponsor5name,
            sponsor5link,
            sponsor5image,
            sponsor6name,
            sponsor6link,
            sponsor6image,
            sponsor7name,
            sponsor7link,
            sponsor7image,
            sponsor8name,
            sponsor8link,
            sponsor8image,
            sponsor9name,
            sponsor9link,
            sponsor9image,
            sponsor10name,
            sponsor10link,
            sponsor10image,
        } = e.target.elements;
        
        var imageRef;
        if (image.files[0]) {
            imageRef = ref(storage, `bank-images/${image.files[0].name}`);
            uploadBytes(imageRef, image.files[0]).then((snapshot) => {
                console.log("Uploaded bank image");
            });
        }
        
        var sponsor1Ref;
        if (sponsor1image.files[0]) {
            sponsor1Ref = ref(storage, `bank-images/${sponsor1image.files[0].name}`);
            uploadBytes(sponsor1Ref, sponsor1image.files[0]).then((snapshot) => {
                console.log("Uploaded image 1");
            });
        }
        
        var sponsor2Ref;
        if (sponsor2image.files[0]) {
            sponsor2Ref = ref(storage, `bank-images/${sponsor2image.files[0].name}`);
            uploadBytes(sponsor2Ref, sponsor2image.files[0]).then((snapshot) => {
                console.log("Uploaded image 2");
            });
        }
        
        var sponsor3Ref;
        if (sponsor3image.files[0]) {
            sponsor3Ref = ref(storage, `bank-images/${sponsor3image.files[0].name}`);
            uploadBytes(sponsor3Ref, sponsor3image.files[0]).then((snapshot) => {
                console.log("Uploaded image 3");
            });
        }

        var sponsor4Ref;
        if (sponsor4image.files[0]) {
            sponsor4Ref = ref(storage, `bank-images/${sponsor4image.files[0].name}`);
            uploadBytes(sponsor4Ref, sponsor4image.files[0]).then((snapshot) => {
                console.log("Uploaded image 4");
            });
        }

        var sponsor5Ref;
        if (sponsor5image.files[0]) {
            sponsor5Ref = ref(storage, `bank-images/${sponsor5image.files[0].name}`);
            uploadBytes(sponsor5Ref, sponsor5image.files[0]).then((snapshot) => {
                console.log("Uploaded image 5");
            });
        }

        var sponsor6Ref;
        if (sponsor6image.files[0]) {
            sponsor6Ref = ref(storage, `bank-images/${sponsor6image.files[0].name}`);
            uploadBytes(sponsor6Ref, sponsor6image.files[0]).then((snapshot) => {
                console.log("Uploaded image 6");
            });
        }

        var sponsor7Ref;
        if (sponsor7image.files[0]) {
            sponsor7Ref = ref(storage, `bank-images/${sponsor7image.files[0].name}`);
            uploadBytes(sponsor7Ref, sponsor7image.files[0]).then((snapshot) => {
                console.log("Uploaded image 7");
            });
        }

        var sponsor8Ref;
        if (sponsor8image.files[0]) {
            sponsor8Ref = ref(storage, `bank-images/${sponsor8image.files[0].name}`);
            uploadBytes(sponsor8Ref, sponsor8image.files[0]).then((snapshot) => {
                console.log("Uploaded image 8");
            });
        }

        var sponsor9Ref;
        if (sponsor9image.files[0]) {
            sponsor9Ref = ref(storage, `bank-images/${sponsor9image.files[0].name}`);
            uploadBytes(sponsor9Ref, sponsor9image.files[0]).then((snapshot) => {
                console.log("Uploaded image 9");
            });
        }

        var sponsor10Ref;
        if (sponsor10image.files[0]) {
            sponsor10Ref = ref(storage, `bank-images/${sponsor10image.files[0].name}`);
            uploadBytes(sponsor10Ref, sponsor10image.files[0]).then((snapshot) => {
                console.log("Uploaded image 10");
            });
        }

        let bankForm = {
            name: name.value,
            goal: goal.value,
            treesPlanted: trees.value,
            benches: benches.value,
            moneyRaised: raised.value,
            quantityRecycled: quantity.value,
            website: website.value,
            postcode: postcode.value,
            twitter: twitter.value,
            facebook: facebook.value,
            instagram: instagram.value,
            email: email.value,
            mapEmbed: mapEmbed.value,
            image: imageRef ? imageRef.fullPath : "",
            password: password.value,
            sponsor1name: sponsor1name.value,
            sponsor2name: sponsor2name.value,
            sponsor3name: sponsor3name.value,
            sponsor4name: sponsor4name.value,
            sponsor5name: sponsor5name.value,
            sponsor6name: sponsor6name.value,
            sponsor7name: sponsor7name.value,
            sponsor8name: sponsor8name.value,
            sponsor9name: sponsor9name.value,
            sponsor10name: sponsor10name.value,
            sponsor1link: sponsor1link.value,
            sponsor2link: sponsor2link.value,
            sponsor3link: sponsor3link.value,
            sponsor4link: sponsor4link.value,
            sponsor5link: sponsor5link.value,
            sponsor6link: sponsor6link.value,
            sponsor7link: sponsor7link.value,
            sponsor8link: sponsor8link.value,
            sponsor9link: sponsor9link.value,
            sponsor10link: sponsor10link.value,
            sponsor1image: sponsor1Ref ? sponsor1Ref.fullPath : "",
            sponsor2image: sponsor2Ref ? sponsor2Ref.fullPath : "",
            sponsor3image: sponsor3Ref ? sponsor3Ref.fullPath : "",
            sponsor4image: sponsor4Ref ? sponsor4Ref.fullPath : "",
            sponsor5image: sponsor5Ref ? sponsor5Ref.fullPath : "",
            sponsor6image: sponsor6Ref ? sponsor6Ref.fullPath : "",
            sponsor7image: sponsor7Ref ? sponsor7Ref.fullPath : "",
            sponsor8image: sponsor8Ref ? sponsor8Ref.fullPath : "",
            sponsor9image: sponsor9Ref ? sponsor9Ref.fullPath : "",
            sponsor10image: sponsor10Ref ? sponsor10Ref.fullPath : "",
        }

        // Process map embed to find latitude and longitude
        const mapEmbedSplit = mapEmbed.value.split("!");
        console.log(mapEmbedSplit);
        let longfound = false;
        let latfound = false;
 
        //Fing Longitude
        for (var i = 0; i < mapEmbedSplit.length; i++) {
            if (mapEmbedSplit[i].substring(0, 2) === "2d") {
                bankForm["longitude"] = mapEmbedSplit[i].split("d")[1];
                longfound = true;
            }
            if (mapEmbedSplit[i].substring(0, 2) === "3d") {
                bankForm["latitude"] = mapEmbedSplit[i].split("d")[1];
                latfound = true;
            }
            if (longfound && latfound) {
                break;
            }
        }

        setKey("AIzaSyCuEx43ft9p0r4ZgsmheRMuyQHlgy2Yv9w");
        setLanguage("en");
        setRegion("gb");
        fromAddress(postcode.value).then(
            (response) => {
                const {lat, lng} = response.results[0].geometry.location;
                console.log(lat, lng);
                bankForm["latitude"] = lat;
                bankForm["longitude"] = lng;
            },
            (error) => {
                console.error(error);
            }
        )

        const lat = bankForm["latitude"];
        const lng = bankForm["longitude"];
        console.log("Latitude: " + lat);
        console.log("Longitude: " + lng);

        let hash = geohash.encode(lat, lng);
        console.log("Geohash: " + hash);
        bankForm["geohash"] = hash;

        /*if (String(email.value).includes("@")) {
            createUserWithEmailAndPassword(auth, email.value, password.value)
                .then((userCredential) => {
                    console.log("User Created");
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                });
        }*/

        var tempBankId = bankId.value.toLowerCase();
        tempBankId = tempBankId.trim();
        tempBankId = tempBankId.replace(/\s+/g, '-');

        await setDoc(doc(db, 'banks', tempBankId), bankForm)
        .then(function() {
            navigate(`/bank?bankId=${tempBankId}`);
        });
        console.log(bankForm);

        alert("Bank Added");
    }

    return (
        <PageContainer>
            <Header />
            <PageContent>
                <Title>Add a Clothes Bank</Title>
                { userStatus ?
                <Form ref={form} onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Name</label>
                        <input className="form-control" type="text" id="name" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Bank ID (used in the URL and cannot contain spaces)</label>
                        <input className="form-control" type="text" id="bankId" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="goal">Goal</label>
                        <input className="form-control" type="text" id="goal" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="trees">Trees / Donate Link</label>
                        <input className="form-control" type="text" id="trees" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="benches">Benches</label>
                        <input className="form-control" type="text" id="benches" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="raised">Raised</label>
                        <input className="form-control" type="text" id="raised" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="quantity">Quantity Recycled</label>
                        <input className="form-control" type="text" id="quantity" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="website">Website</label>
                        <input className="form-control" type="text" id="website" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="postcode">Postcode</label>
                        <input className="form-control" type="text" id="postcode" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="twitter">Twitter</label>
                        <input className="form-control" type="text" id="twitter" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="facebook">Facebook</label>
                        <input className="form-control" type="text" id="facebook" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="instagram">Instagram</label>
                        <input className="form-control" type="text" id="instagram" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="email">Email</label>
                        <input className="form-control" type="text" id="email" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="password">Password</label>
                        <input className="form-control" type="password" id="password" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Map Embed Code</label>
                        <input className="form-control" type="text" id="mapEmbed" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="image">School Image</label>
                        <input className="form-control" type="file" id="image" />
                    </div>
                    {
                        looper.map((item) => {
                            return (
                                <div className={`sponsor${item}`}>
                                    <div className="form-field-container">
                                        <label className="form-label" htmlFor={`sponsor${item}name`}>Sponsor {item} Name</label>
                                        <input className="form-control" type="text" id={`sponsor${item}name`} required />
                                    </div>
                                    <div className="form-field-container">
                                        <label className="form-label" htmlFor={`sponsor${item}link`}>Sponsor {item} Link</label>
                                        <input className="form-control" type="text" id={`sponsor${item}link`} required />
                                    </div>
                                    <div className="form-field-container">
                                        <label className="form-label" htmlFor={`sponsor${item}image`}>Sponsor {item} Image</label>
                                        <input className="form-control" type="file" id={`sponsor${item}image`} />
                                    </div>
                                </div>
                            )
                        })
                    }
                    <button className="btn btn-danger" type="submit">
                        {formStatus}
                    </button>
                </Form> :
                <p>You are not logged in.</p>
                }
            </PageContent>
        </PageContainer>
    )
}

export default AddBank;

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    padding-left: 18px;
    margin: 0;
`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 24px;
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        border-radius: 0 24px 0 24px;
        padding: 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        font-weight: bold;
        margin-top: 18px;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`