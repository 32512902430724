import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { db, storage, auth } from '../util/firebaseApp';
import { doc, updateDoc, getDoc, Collection } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { onAuthStateChanged } from 'firebase/auth';
import {
    setKey,
    setDefaults,
    setLanguage,
    setRegion,
    fromAddress,
    fromLatLng,
    fromPlaceId,
    setLocationType,
    geocode,
    RequestType,
  } from "react-geocode";
import geohash from 'ngeohash'; 
import { geohashForLocation } from "geofire-common";

const EditBank = () => {
    const [userStatus, setUserStatus] = useState(false);
    const [formStatus, setFormStatus] = useState('Send');
    const [userID, setUserID] = useState("");
    const [bank, setBank] = useState({});
    const [bankId, setBankId] = useState("u6UdNFZfueGHHBcG0a2C");
    const [searchParams] = useSearchParams();
    const [bankImg, setImage] = useState("");
    const [sponsors, setSponsors] = useState({});
    const navigate = useNavigate();
    const [progressPercent, setProgressPercent] = useState(0);

    const [bankVideoRef, setBankVideoRef] = useState();

    const looper = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUserID(user.uid);
          setUserStatus(true);
        } else {
          setUserStatus(false);
        }
    });

    const fetchBankData = async () => {
        const docRef = doc(db, "banks", bankId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setBank(docSnap.data());
            return docSnap.data();
        }
    }

    const fetchBankPhoto = async () => {
        const imgUrl = getDownloadURL(ref(storage, bank.image ? bank.image : "bank-images/generic-school.jpg"))
            .then((url) => {
                setImage(url);
            })
    }

    const uploadBankPhoto = async (image) => {
        console.log("Uploading");
        var imageRef;
        /*if (image.files[0]) {
            console.log("Filename: " + image.files[0].name);
            imageRef = ref(storage, `bank-images/${image.files[0].name}`);
            var uploadTask = uploadBytesResumable(imageRef, image.files[0]);
            uploadTask.on('state_changed', function(snapshot){
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {
                  case storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                var downloadURL = uploadTask.snapshot.downloadURL;
                console.log("Download URL: " + downloadURL);
              });

            setBankVideoRef(imageRef.fullPath);
        }*/
    }

    const fetchSponsors = async () => {
        const sponsorsData = bank.sponsors;
        var sponsorRefs = [];
        for (var key in sponsorsData) {
            sponsorRefs.push(sponsorsData[key]._key.path.segments[6]);
        }

        var sponsors = [];
        sponsorRefs.forEach(async (sponsor) => {
            const docRef = doc(db, "sponsors", sponsor);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                sponsors.push(docSnap.data());
                setSponsors(sponsors);
            }
        })
    }

    useEffect(() => {
        setBankId(searchParams.get('bankId'));
    }, []);

    useEffect(() => {
        fetchBankData();
    }, [bankId]);

    useEffect(() => {
        fetchBankPhoto();
        fetchSponsors();
    }, [bank]);
    
    useEffect(() => {
        console.log(sponsors)
    }, [sponsors]);

    const onSubmit = async (e) => {
        console.log("Sending");
        e.preventDefault();
        //setFormStatus("Submitting...");
        
        const { name, 
            email, 
            quantity, 
            image, 
            website, 
            postcode,
            twitter, 
            mapEmbed, 
            facebook, 
            instagram, 
            trees, 
            goal, 
            benches, 
            raised,
            sponsor1name,
            sponsor1link,
            sponsor1image,
            sponsor2name,
            sponsor2link,
            sponsor2image,
            sponsor3name,
            sponsor3link,
            sponsor3image,
            sponsor4name,
            sponsor4link,
            sponsor4image,
            sponsor5name,
            sponsor5link,
            sponsor5image,
            sponsor6name,
            sponsor6link,
            sponsor6image,
            sponsor7name,
            sponsor7link,
            sponsor7image,
            sponsor8name,
            sponsor8link,
            sponsor8image,
            sponsor9name,
            sponsor9link,
            sponsor9image,
            sponsor10name,
            sponsor10link,
            sponsor10image,
        } = e.target.elements;

        

        let bankForm = {
            name: name.value,
            goal: goal.value,
            treesPlanted: trees.value,
            benches: benches.value,
            moneyRaised: raised.value,
            quantityRecycled: quantity.value,
            website: website.value,
            postcode: postcode.value,
            twitter: twitter.value,
            facebook: facebook.value,
            instagram: instagram.value,
            email: email.value,
            mapEmbed: mapEmbed.value,
            sponsor1name: sponsor1name.value,
            sponsor2name: sponsor2name.value,
            sponsor3name: sponsor3name.value,
            sponsor4name: sponsor4name.value,
            sponsor5name: sponsor5name.value,
            sponsor6name: sponsor6name.value,
            sponsor7name: sponsor7name.value,
            sponsor8name: sponsor8name.value,
            sponsor9name: sponsor9name.value,
            sponsor10name: sponsor10name.value,
            sponsor1link: sponsor1link.value,
            sponsor2link: sponsor2link.value,
            sponsor3link: sponsor3link.value,
            sponsor4link: sponsor4link.value,
            sponsor5link: sponsor5link.value,
            sponsor6link: sponsor6link.value,
            sponsor7link: sponsor7link.value,
            sponsor8link: sponsor8link.value,
            sponsor9link: sponsor9link.value,
            sponsor10link: sponsor10link.value,
        }

        var imageRef;
        if (image.files[0]) {
            imageRef = ref(storage, `bank-images/${image.files[0].name}`);
            const uploadTask = uploadBytesResumable(imageRef, image.files[0]);
            uploadTask.on("state_changed",
            (snapshot) => {
                const progress = 
                    Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgressPercent(progress);
            },
            (error) => {
                alert(error);
            });
            /*uploadBytes(imageRef, image.files[0]).then((snapshot) => {
                console.log("Uploaded image");
            });*/
            bankForm["image"] = imageRef.fullPath;
        }

        var sponsor1Ref;
        if (sponsor1image.files[0]) {
            sponsor1Ref = ref(storage, `bank-images/${sponsor1image.files[0].name}`);
            uploadBytes(sponsor1Ref, sponsor1image.files[0]).then((snapshot) => {
                console.log("Uploaded image 1");
                console.log("Sponsor1fullpath: " + sponsor1Ref.fullPath);
            });
            bankForm["sponsor1image"] = sponsor1Ref.fullPath;
        }
        var sponsor2Ref;
        if (sponsor2image.files[0]) {
            sponsor2Ref = ref(storage, `bank-images/${sponsor2image.files[0].name}`);
            uploadBytes(sponsor2Ref, sponsor2image.files[0]).then((snapshot) => {
                console.log("Uploaded image 2");
            });
            bankForm["sponsor2image"] = sponsor2Ref.fullPath;
        }
        var sponsor3Ref;
        if (sponsor3image.files[0]) {
            sponsor3Ref = ref(storage, `bank-images/${sponsor3image.files[0].name}`);
            uploadBytes(sponsor3Ref, sponsor3image.files[0]).then((snapshot) => {
                console.log("Uploaded image 3");
            });
            bankForm["sponsor3image"] = sponsor3Ref.fullPath;
        }
        var sponsor4Ref;
        if (sponsor4image.files[0]) {
            sponsor4Ref = ref(storage, `bank-images/${sponsor4image.files[0].name}`);
            uploadBytes(sponsor4Ref, sponsor4image.files[0]).then((snapshot) => {
                console.log("Uploaded image 4");
            });
            bankForm["sponsor4image"] = sponsor4Ref.fullPath;
        }
        var sponsor5Ref;
        if (sponsor5image.files[0]) {
            sponsor5Ref = ref(storage, `bank-images/${sponsor5image.files[0].name}`);
            uploadBytes(sponsor5Ref, sponsor5image.files[0]).then((snapshot) => {
                console.log("Uploaded image 5");
            });
            bankForm["sponsor5image"] = sponsor5Ref.fullPath;
        }
        var sponsor6Ref;
        if (sponsor6image.files[0]) {
            sponsor6Ref = ref(storage, `bank-images/${sponsor6image.files[0].name}`);
            uploadBytes(sponsor6Ref, sponsor6image.files[0]).then((snapshot) => {
                console.log("Uploaded image 6");
            });
            bankForm["sponsor6image"] = sponsor6Ref.fullPath;
        }
        var sponsor7Ref;
        if (sponsor7image.files[0]) {
            sponsor7Ref = ref(storage, `bank-images/${sponsor7image.files[0].name}`);
            uploadBytes(sponsor7Ref, sponsor7image.files[0]).then((snapshot) => {
                console.log("Uploaded image 7");
            });
            bankForm["sponsor7image"] = sponsor7Ref.fullPath;
        }
        var sponsor8Ref;
        if (sponsor8image.files[0]) {
            sponsor8Ref = ref(storage, `bank-images/${sponsor8image.files[0].name}`);
            uploadBytes(sponsor8Ref, sponsor8image.files[0]).then((snapshot) => {
                console.log("Uploaded image 8");
            });
            bankForm["sponsor8image"] = sponsor8Ref.fullPath;
        }
        var sponsor9Ref;
        if (sponsor9image.files[0]) {
            sponsor9Ref = ref(storage, `bank-images/${sponsor9image.files[0].name}`);
            uploadBytes(sponsor9Ref, sponsor9image.files[0]).then((snapshot) => {
                console.log("Uploaded image 9");
            });
            bankForm["sponsor9image"] = sponsor9Ref.fullPath;
        }
        var sponsor10Ref;
        if (sponsor10image.files[0]) {
            sponsor10Ref = ref(storage, `bank-images/${sponsor10image.files[0].name}`);
            uploadBytes(sponsor10Ref, sponsor10image.files[0]).then((snapshot) => {
                console.log("Uploaded image 10");
            });
            bankForm["sponsor10image"] = sponsor10Ref.fullPath;
        }

        // Process map embed to find latitude and longitude
        const mapEmbedSplit = mapEmbed.value.split("!");
        console.log(mapEmbedSplit);
        let longfound = false;
        let latfound = false;
 
        //Fing Longitude
        for (var i = 0; i < mapEmbedSplit.length; i++) {
            if (mapEmbedSplit[i].substring(0, 2) === "2d") {
                bankForm["longitude"] = mapEmbedSplit[i].split("d")[1];
                longfound = true;
            }
            if (mapEmbedSplit[i].substring(0, 2) === "3d") {
                bankForm["latitude"] = mapEmbedSplit[i].split("d")[1];
                latfound = true;
            }
            if (longfound && latfound) {
                break;
            }
        }

        // Set Geocode for provided address data
        setKey("AIzaSyCuEx43ft9p0r4ZgsmheRMuyQHlgy2Yv9w");
        setLanguage("en");
        setRegion("gb");
        fromAddress(postcode.value).then(
            (response) => {
                const {lat, lng} = response.results[0].geometry.location;
                console.log(lat, lng);
                bankForm["latitude"] = lat;
                bankForm["longitude"] = lng;
                //bankForm["geohash"] = geofire.geohashForLocation([lat], [lng]);
                //console.log("Geohash: " + geofire.geohashForLocation([lat], [lng]));
            },
            (error) => {
                console.error(error);
            }
        )

        const lat = bankForm["latitude"];
        const lng = bankForm["longitude"];
        console.log("Latitude: " + lat);
        console.log("Longitude: " + lng);

        let hash = geohashForLocation([parseFloat(lat), parseFloat(lng)]);
        console.log("Geohash: " + hash);
        bankForm["geohash"] = hash;
        console.log("Bank ID: " + bankId);
        await updateDoc(doc(db, 'banks', bankId), bankForm)
            .then(function() {
                alert("updated");
                navigate(`/bank?bankId=${bankId}`);
            });
        console.log(bankForm);
    }

    return (
        <PageContainer>
            <Header />
            <PageContent>
                <Title>Change Clothes Bank Details</Title>
                { userStatus ?
                <Form onSubmit={onSubmit} className="sponsor-form" noValidate>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Name</label>
                        <input className="form-control" defaultValue={bank.name} type="text" id="name" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="goal">Goal</label>
                        <input className="form-control" type="text" defaultValue={bank.goal} id="goal" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="trees">Trees / Donate Link</label>
                        <input className="form-control" type="text" defaultValue={bank.treesPlanted} id="trees" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="benches">Benches</label>
                        <input className="form-control" type="text" defaultValue={bank.benches} id="benches" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="raised">Raised</label>
                        <input className="form-control" type="text" defaultValue={bank.moneyRaised} id="raised" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="quantity">Quantity Recycled</label>
                        <input className="form-control" type="text" defaultValue={bank.quantityRecycled} id="quantity" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="postcode">Address</label>
                        <input className="form-control" type="text" defaultValue={bank.postcode} id="postcode" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="website">Website</label>
                        <input className="form-control" type="text" defaultValue={bank.website} id="website" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="twitter">Twitter</label>
                        <input className="form-control" type="text" defaultValue={bank.twitter} id="twitter" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="facebook">Facebook</label>
                        <input className="form-control" type="text" defaultValue={bank.facebook} id="facebook" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="instagram">Instagram</label>
                        <input className="form-control" type="text" defaultValue={bank.instagram} id="instagram" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="email">Email</label>
                        <input className="form-control" type="text" defaultValue={bank.email} id="email" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="name">Map Embed Code</label>
                        <input className="form-control" type="text" defaultValue={bank.mapEmbed} id="mapEmbed" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="image">School Image</label>
                        <input className="form-control" type="file" id="image" />
                    </div>
                    <div className="form-field-container form-upload-button-container">
                        <button className="upload-button" onClick={() => { uploadBankPhoto(document.getElementById("image")); }}>Upload Image</button>
                    </div>
                    <div className="current-bank-image-container">
                        <img src={bankImg} alt={bank.name} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor1name`}>Sponsor 1 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor1name} id={`sponsor1name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor1link`}>Sponsor 1 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor1link} id={`sponsor1link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor1image`}>Sponsor 1 Image</label>
                        <input className="form-control" type="file" id={`sponsor1image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor2name`}>Sponsor 2 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor2name} id={`sponsor2name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor2link`}>Sponsor 2 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor2link} id={`sponsor2link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor2image`}>Sponsor 2 Image</label>
                        <input className="form-control" type="file" id={`sponsor2image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor3name`}>Sponsor 3 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor3name} id={`sponsor3name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor3link`}>Sponsor 3 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor3link} id={`sponsor3link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor3image`}>Sponsor 3 Image</label>
                        <input className="form-control" type="file" id={`sponsor3image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor4name`}>Sponsor 4 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor4name} id={`sponsor4name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor4link`}>Sponsor 4 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor4link} id={`sponsor4link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor4image`}>Sponsor 4 Image</label>
                        <input className="form-control" type="file" id={`sponsor4image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor5name`}>Sponsor 5 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor5name} id={`sponsor5name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor5link`}>Sponsor 5 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor5link} id={`sponsor5link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor5image`}>Sponsor 5 Image</label>
                        <input className="form-control" type="file" id={`sponsor5image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor6name`}>Sponsor 6 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor6name} id={`sponsor6name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor6link`}>Sponsor 6 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor6link} id={`sponsor6link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor6image`}>Sponsor 6 Image</label>
                        <input className="form-control" type="file" id={`sponsor6image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor7name`}>Sponsor 7 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor7name} id={`sponsor7name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor7link`}>Sponsor 7 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor7link} id={`sponsor7link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor7image`}>Sponsor 7 Image</label>
                        <input className="form-control" type="file" id={`sponsor7image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor8name`}>Sponsor 8 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor8name} id={`sponsor8name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor8link`}>Sponsor 8 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor8link} id={`sponsor8link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor8image`}>Sponsor 8 Image</label>
                        <input className="form-control" type="file" id={`sponsor8image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor9name`}>Sponsor 9 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor9name} id={`sponsor9name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor9link`}>Sponsor 9 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor9link} id={`sponsor9link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor9image`}>Sponsor 9 Image</label>
                        <input className="form-control" type="file" id={`sponsor9image`} />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor10name`}>Sponsor 10 Name</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor10name} id={`sponsor10name`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor10link`}>Sponsor 10 Link</label>
                        <input className="form-control" type="text" defaultValue={bank.sponsor10link} id={`sponsor10link`} required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor={`sponsor10image`}>Sponsor 10 Image</label>
                        <input className="form-control" type="file" id={`sponsor10image`} />
                    </div>
                    <button className="btn btn-danger" type="submit">
                        {formStatus}
                    </button>
                </Form> :
                <p>You are not logged in.</p>
                }
            </PageContent>
        </PageContainer>
    )
}

export default EditBank;

const PageContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const PageContent = styled.div`
    max-width: 1296px;
    width: 100%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const Title = styled.h1`
    font-size: 48px;
    font-weight: normal;
    padding-left: 18px;
    margin: 0;
`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 470px;
    width: 100%;
    padding: 24px;
    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    .current-bank-image-container {
        max-width: 422px;
        overflow: hidden;
        height: auto;
    }

    .current-bank-image-container img {
        width: 100%;
        height: auto;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        border-radius: 0 24px 0 24px;
        padding: 24px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        font-weight: bold;
        margin-top: 18px;
    }

    button.upload-button {
        padding: 8px 12px;
        border-radius: 4px;
        margin-top: 0;
    }

    .form-upload-button-container {
        margin: 0;
        display: none;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`