import React from 'react'
import styled from 'styled-components'
import { auth } from '../util/firebaseApp';
import { getAuth, signOut } from 'firebase/auth';

const AdminMenu = (props) => {
    return (
        <Container>
            <MenuButton href="/account">Bank List</MenuButton>
            <BankEditButton href={`/edit-bank?bankId=${props.bankId}`}>Edit Bank</BankEditButton>  
            <MenuButton onClick={() => { logout() }}>Logout</MenuButton>
        </Container>
    )
}

const logout = () => {
    console.log("Logging Out...");
    const auth = getAuth();
    signOut(auth).then(() => {
        console.log("Sign Out Successful");
    }).catch((error) => {
        console.log("Unable to Sign Out")
    })
}

export default AdminMenu

const Container = styled.div`
    z-index: 100;
    position: absolute;
    margin-top: 15px;
    top: 15px;
    width: 100%;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 18px;
    margin: 0 auto;
`

const MenuButton = styled.a`
    background: black;
    color: white;
    font-weight: bold;
    padding: 8px;
    text-decoration: none;
`

const BankEditButton = styled(MenuButton)`
    display: block;
`