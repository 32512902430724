import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import firebase from 'firebase/app'
import { db } from '../util/firebaseApp'
import { doc, setDoc, getDoc, updateDoc, FieldValue } from 'firebase/firestore'
import { EmailShareButton, FacebookMessengerShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { FacebookIcon, TwitterIcon, FacebookMessengerIcon, EmailIcon, WhatsappIcon } from "react-share";
import { InlineShareButtons, StickyShareButtons } from 'sharethis-reactjs'

const ShareOptions = ({rewardPoints, exitFunction, firstName}) => {
    return (
        <ShareOptionContainer>
            {
                rewardPoints > 0 ?
                <p>Hi {firstName}, you have {rewardPoints} points at the moment.</p> :
                <p>You don't have any</p>
            }
        </ShareOptionContainer>
    )
}

const SharePopup = ({ exitFunction }) => {
    const [shareStatus, setShareStatus] = useState(false);
    const [formStatus, setFormStatus] = useState('Submit');
    const [currentPoints, setPoints] = useState(0);
    const [firstName, setFirst] = useState("");
    const [formVisible, setFormVisibility] = useState(true);
    console.log(window.location.href);
    const onSubmit = async (e) => {
        e.preventDefault();
        setFormStatus("Submitting...");
        const { first, last, email } = e.target.elements;

        let sponForm = {
            first: first.value,
            last: last.value,
            email: email.value,
            
        }
        const rewardRef = doc(db, 'rewardPoints', email.value);
        const rewardDoc = await getDoc(rewardRef);
        var rewardPoints = 0;
        // Check if doc exists
        if (rewardDoc.data()) {
            console.log("Doc exists");
            rewardPoints = rewardDoc.data().rewardPoints + 1;
            setPoints(rewardPoints);

            await updateDoc(rewardRef, {
                first: first.value,
                last: last.value,
                email: email.value,
                rewardPoints: rewardPoints,
            });
        } else {
            console.log("Doc not found");
            setPoints(1);
            await setDoc(rewardRef, {
                first: first.value,
                last: last.value,
                email: email.value,
                rewardPoints: 1,
            });
        }

        setFirst(first.value);
        setShareStatus(true);
        setFormVisibility(false);
    }

    return (
        <Container>
            <ShareContainer>
                <CloseButton onClick={() => {exitFunction(false)}}>X</CloseButton>
                <h2>Share this Clothes Bank</h2>
                
                <ShareButtonsContainer>
                <InlineShareButtons
                config={{
                    alignment: 'center',  // alignment of buttons (left, center, right)
                    color: 'social',      // set the color of buttons (social, white)
                    enabled: true,        // show/hide buttons (true, false)
                    font_size: 16,        // font size for the buttons
                    labels: 'cta',        // button labels (cta, counts, null)
                    language: 'en',       // which language to use (see LANGUAGES)
                    networks: [           // which networks to include (see SHARING NETWORKS)
                    'whatsapp',
                    'email',
                    'messenger',
                    'facebook',
                    'twitter',
                    'sms'
                    ],
                    padding: 12,          // padding within buttons (INTEGER)
                    radius: 4,            // the corner radius on each button (INTEGER)
                    show_total: false,
                    show_mobile: true,
                    size: 40,             // the size of each button (INTEGER)
                }}
            />
                </ShareButtonsContainer>
            </ShareContainer>
        </Container>
    )
}

export default SharePopup

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
`

const ShareContainer = styled.div`
    background: white;
    position: relative;
    margin: auto;
    border-radius: 18px;
    padding: 18px;
    display: flex;
    flex-direction: column;

    h2 {
        font-weight: 600;
        font-size: 36px;
        margin-bottom: 0;
    }

    input {
        margin: 4px 0;
        width: 100%;
        font-size: 1.5rem;
        padding: 8px;
        max-width: auto;
        width: auto;
    }
    
    button {
        max-width: 96px;
        font-size: 18px;
        padding: 8px;
        border: 0;
        background: #ECB525;
        color: white;
        font-weight: normal;
    }
`

const ShareOptionContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
`

const Form = styled.form`
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 540px;
    width: 100%;

    .form-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 21px;
    }

    .form-field-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        margin-top: 24px;
    }

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
    }

    #adPhoto {
        padding: 0;
    }

    label {
        margin-bottom: 8px;
        font-size: 18px;
        box-sizing: border-box;
    }

    button {
        border: 0;
        padding: 8px 18px;
        font-size: 18px;
        background: #AF3D58;
        color: white;
        font-weight: normal;
        margin-top: 18px;
        margin-bottom: 22px;
    }

    .form-field-container.checkbox {
        flex-direction: row;
        justify-content: flex-start;
    }

    .form-field-container.checkbox label {
        order: 2;
    }

    .form-field-container.checkbox input {
        order: 1;
    }

    #acceptTick {
        width: auto;
    }
`

const CloseButton = styled.a`
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 32px;
    color: black;
    cursor: default;
`

const ShareButtonsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: center;
    gap: 22px;
`

/*

const ShareText = () => {
    return (
        <div>
            <h3>Earn points by sharing!</h3>
            <p>Enter your details to earn points and see how many you have.</p>
            {formVisible ?
            <Form onSubmit={onSubmit} className="sponsor-form" noValidate>
                <div className="form-row">
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="first">First Name</label>
                        <input className="form-control" type="text" id="first" required />
                    </div>
                    <div className="form-field-container">
                        <label className="form-label" htmlFor="last">Last Name</label>
                        <input className="form-control" type="text" id="last" required />
                    </div>
                </div>
                <div className="form-field-container">
                    <label className="form-label" htmlFor="email">Email</label>
                    <input className="form-control" type="text" id="email" required />
                </div>
                <button className="btn btn-danger" type="submit">
                    {formStatus}
                </button>
            </Form> :
            ""
            }

            {shareStatus ? <ShareOptions firstName={firstName} rewardPoints={currentPoints} /> : ""}
        </div>
    )
}
*/

const CopyLinkButton = styled.a`
    width: 48px;
    height: 48px;
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 32px;
        fill: white;
        height: 32px;
    }
`