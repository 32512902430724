import React, { useState, useEffect } from 'react';
import algoliasearch from 'algoliasearch/lite'
import styled from 'styled-components';
import Header from '../components/Header';
import { db, storage, auth } from '../util/firebaseApp'
import { doc, collection, getDocs, query, where, getDoc, getCountFromServer } from 'firebase/firestore'
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { InstantSearch, SearchBox, RefinementList, InfiniteHits } from 'react-instantsearch-hooks-web'
import BankHitComponent from '../components/BankHitComponent';

const Account = () => {
    const [banks, setBanks] = useState([]);
    const [userStatus, setUserStatus] = useState(false);
    const [bankCount, setBankCount] = useState(0);
    const [userID, setUserID] = useState("");
    const navigate = useNavigate();
    const [userEmail, setUserEmail] = useState("");
    const [userRole, setUserRole] = useState("default");

    const searchClient = algoliasearch(
        "Q59KO8R6QB",
        "5656a17786be65f4ad99d4f1e8b00fe4"
    )

    const searchIndex = searchClient.initIndex("fr_banks");

    const getUser = async () => {
        if (userEmail) {
            const docRef = doc(db, "users", userEmail);
            const docSnap = await getDoc(docRef);
    
            if (docSnap.exists()) {
                setUserRole(docSnap.data().role);
            }
        }
        
    }

    onAuthStateChanged(auth, (user) => {
        if (user) {
          setUserID(user.uid);
          setUserEmail(user.email);
          setUserStatus(true);
        } else {
          setUserStatus(false);
        }
    });

    const getAllBanks = async () => {
        var banksArray = []
        const querySnapshot = await getDocs(collection(db, 'banks'));
        querySnapshot.forEach((doc) => {
            banksArray.push(doc);
        })
        setBanks(banksArray);

        const coll = collection(db, "banks");
        const snapshot = await getCountFromServer(coll);
        console.log('count: ', snapshot.data().count);
        getCountFromServer(coll)
            .then((snapshot) => {
                setBankCount(snapshot.data().count)
                console.log("Count: " + snapshot.data())
            });
    }

    const getUserBanks = async () => {
        var banksArray = []
        const q = query(collection(db, 'banks'), where('email', '==', userEmail));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            banksArray.push(doc);
        })
        setBanks(banksArray);
    }

    useEffect(() => {
        //console.log("User Role: " + userRole);
        if (userRole === "admin") {
            getAllBanks();
        } else {
            getUserBanks();
        }
    }, [userRole])

    useEffect(() => {
        getUser();
    }, [userEmail])

    const BanksList = () => {
        return (
            <BankListContainer>
                {banks.map((bank) =>
                    <BankListItem key={bank.data().name}>
                        <BankName onClick={() => {navigate(`/bank?bankId=${bank._key.path.segments[6]}`)}}>{bank.data().name}</BankName>
                        <BankEditButton href={`/edit-bank?bankId=${bank._key.path.segments[6]}`}>Edit</BankEditButton>
                    </BankListItem>
                )}
            </BankListContainer>
        )
    }

    return (
        <PageContainer>
            <Header />
            {
                userStatus ?
                <InfoRow>
                    <BankCountText>{bankCount} Clothes Banks in the Database</BankCountText>
                    <LoggedInStatusText>Logged in as: {userEmail}</LoggedInStatusText>
                </InfoRow>
                :
                ""
            }
            {
                userStatus ?
                <AddBankButton>
                    <a href="/add-bank">Add Clothes Bank</a>
                </AddBankButton> :
                ""
            }
            {
                userStatus ?
                <InstantSearch
                    searchClient={searchClient}
                    indexName='fr_banks'
                >
                    <SearchHits>
                        <SearchBox placeholder="Search for banks..." />
                        <InfiniteHits hitComponent={BankHitComponent} />
                    </SearchHits>
                </InstantSearch> :
                <p>Not logged in</p>
            }
        </PageContainer>
    )
}

export default Account

const PageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
`

const BankName = styled.h2`
    font-size: 24px;
    color: black;
`

const BankListContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const BankListItem = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
`

const BankEditButton = styled.a`
    color: blue;
    font-weight: normal;
    margin: 24px 12px;
    font-size: 18px;
    text-decoration: none;
    display: block;
`

const AddBankButton = styled.div`
    margin: 24px 0 24px 0;

    a {
        text-decoration: none;
        font-size: 24px;
        border: 1px solid black;
        background: #fafafa;
        color: black;
        padding: 8px 16px;
    }

    a:hover {
        text-decoration: underline;
    }
`

const SearchHits = styled.div`

`

const BankCountText = styled.p`
    font-weight: 500;
    font-size: 26px;
    margin: 0;
`

const LoggedInStatusText = styled.p`
    color: black;
    margin: 0;
`

const InfoRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    padding: 8px 20px;
    box-sizing: border-box;
`