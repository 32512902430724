import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCuEx43ft9p0r4ZgsmheRMuyQHlgy2Yv9w",
  authDomain: "fundraise-recycle-app.firebaseapp.com",
  projectId: "fundraise-recycle-app",
  storageBucket: "fundraise-recycle-app.appspot.com",
  messagingSenderId: "1079037813268",
  appId: "1:1079037813268:web:96ee687fa303c74746bd01",
  measurementId: "G-0TJY89B0T8"
};

const firebase = initializeApp(firebaseConfig);
const auth = getAuth(firebase);
const db = getFirestore(firebase);
const analytics = getAnalytics(firebase);
const storage = getStorage(firebase);

export { firebase, auth, db, analytics, storage };