export const sampleTiles = [
    {
        "title": "Advertise your Business",
        "text": "Want to advertise your business?Click here",
        "link": "/sponsor",
    },
    {
        "title": "Property IGO",
        "link": "https://www.facebook.com/people/Property-IGO/100085026143290/",
        "image": require('../images/fr-bank/property-lgo.jpeg'),
    },
    {
        "title": "Not Just Travel with Bethany",
        "link": "https://www.facebook.com/bethany.hallnjt",  
        "image": require('../images/fr-bank/not-just-travel.jpeg'),
    },
    {
        "title": "North East Car Cosmetics",
        "link": "https://www.facebook.com/watch/?v=240996225240579",
        "image": require('../images/fr-bank/ne-car-cosmetics.jpeg'),
    },
    {
        "title": "Marina's Sweet Treats",
        "link": "https://www.instagram.com/marinassweettreats/?__coig_restricted=1&igshid=YmMyMTA2M2Y%3D",
        "image": require('../images/fr-bank/marinas.jpeg'),
    },
    {
        "title": "Sunderland Sweetie",
        "link": "https://www.instagram.com/sunderlandsweetie/?igshid=YmMyMTA2M2Y%3D",
        "image": require('../images/fr-bank/sweetie.png'),
    },
    {
        "title": "Kids Designer GLC",
        "link": "https://www.instagram.com/kidsdesigner_glc/?igshid=YmMyMTA2M2Y%3D",
        "image": require('../images/fr-bank/kids-designer.png'),
    },
    {
        "title": "EasyMove Lettings",
        "link": "https://www.facebook.com/people/Easymove-Lettings/100063651261724/",
        "image": require('../images/fr-bank/easymove.png'),
    }
];