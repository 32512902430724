import React from 'react';
import styled from 'styled-components';

const DividerStrip = () => {
    return (
        <Strip />
    )
}

export default DividerStrip

const Strip = styled.div`
    width: 100%;
    height: 9px;
    background: #E8E8EA;
    margin: 18px 0;
`