import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams, useNavigate } from 'react-router-dom';
import Header from '../components/Header'
import DividerStrip from '../components/DividerStrip'
import { FRMap, FRVideo } from '../components/FRMap';
import { sampleTiles } from '../assets/data/sampleTiles';
import Footer from '../components/Footer'
import { MailchimpPopup } from '../components/MailchimpForm';
import SharePopup from '../components/SharePopup';
import SocialButtons from '../components/SocialButtons';

import {PageContainer, ExitPopup, HeroSection, MapInfoContent, HeroContent, VideoContainer, VideoStatSection, Title, StatContainer, Stats, RaisedStat, QuantityStat, TreesStat, GoalText, SponsorStrip, CTAStrip, CTAContainer, CTAText, CTAIcon, AdvertStrip, AdvertCardContainer, AdvertCard, MapStrip, MapInfoBox} from '../components/StyledItems'

import { db } from '../util/firebaseApp';
import { ref, doc, getDoc, updateDoc } from 'firebase/firestore'

import WeightImage from '../assets/images/weight.png';
import TreeImage from '../assets/images/trees.png';

const FundraiseRecycleBank = (props) => {
    const [sharedState, setSharedState] = useState(false);
    const [bank, setBank] = useState({});
    const [bankId, setBankId] = useState("");
    const [searchParams] = useSearchParams();
    const [popupActive, setPopupState] = useState(false);
    const [sharePopupActive, setSharePopup] = useState(false);
    const navigate = useNavigate();
    const [shareCount, setShareCount] = useState(0);

    useEffect(() => {
        setBankId(searchParams.get('bankId'));
        document.title = "Fundraise & Recycle";
        getShareCount();
    }, [])

    const getShareCount = async () => {
        const shareCountRef = doc(db, "appData", "data");
        const shareCountSnap = await getDoc(shareCountRef);
        setShareCount(shareCountSnap.data().shareCount);
    }

    const incrementShareCount = async () => {
        if (sharedState) {
            return;
        }
        const shareCountRef = doc(db, "appData", "data");
        const shareCountSnap = await getDoc(shareCountRef);
        var shareCount = shareCountSnap.data().shareCount;
        shareCount++;
        
        await updateDoc(shareCountRef, {
            shareCount: shareCount,
        });

        setShareCount(shareCount);
        setSharedState(true);
    }

    return (
        <PageContainer>
        <SocialButtons />
        {
            popupActive ?
            <ExitPopup onClick={() => {setPopupState(false)}}>X</ExitPopup> :
            ""
        }
        {
            popupActive ?
            <MailchimpPopup exitFunction={setPopupState} /> :
            ""
        }
        {
            sharePopupActive ?
            <SharePopup exitFunction={setSharePopup} /> :
            ""
        }
            <Header isHome={true} />
            <HeroSection>
                <HeroContent>
                    <VideoStatSection>
                        <VideoContainer>
                            <figure><video controls poster="https://fundraise-recycle.s3.us-west-002.backblazeb2.com/frPlaceholder.jpg" src="https://firebasestorage.googleapis.com/v0/b/fundraise-recycle-app.appspot.com/o/WhatsApp%20Video%202023-03-14%20at%2017.33.35.mp4?alt=media&token=4d027fad-bae4-415d-a1f9-5b981a801339"></video></figure>
                        </VideoContainer>
                        <StatContainer>
                            <Title><span className="underline-accent">Fundraise</span> & Recycle</Title>
                            <Stats>
                                <QuantityStat><span>310,000kg</span><br />Recycled</QuantityStat>
                                <RaisedStat><span>£93,000</span><br />Funraised</RaisedStat>
                                <TreesStat><span>100</span><br />Trees Planted</TreesStat>
                            </Stats>
                            <GoalText>Our goal is to create multiple long-term sources of revenue for schools and other organisations from textiles recycling and beyond.</GoalText>
                        </StatContainer>
                    </VideoStatSection>
                </HeroContent>
            </HeroSection>
            <CTAStrip>
                <CTAContainer onClick={() => {navigate("/book-collection");}}>
                    <CTAIcon src={require('../assets/images/CollectionIcon.png')} />
                    <CTAText>BOOK A<br/>COLLECTION</CTAText>
                </CTAContainer>
                <CTAContainer onClick={() => {setPopupState(true)}}>
                    <CTAIcon src={require('../assets/images/MailIcon.png')} />
                    <CTAText>SUBSCRIBE<br/>FOR UPDATES</CTAText>
                </CTAContainer>
                <CTAContainer onClick={() => {
                    if (navigator.share) {
                        navigator.share({
                            title: window.title,
                            text: 'See the best way to raise money and recycle your old clothes.',
                            url: window.location.href,
                        })
                            .then(() => console.log('Successful share'))
                            .catch((error) => console.log('Error sharing', error));
                        incrementShareCount();
                    } else {
                        console.log("Native sharing unsupported");
                        setSharePopup(true);
                        incrementShareCount();
                    }
                }}>
                    <CTAIcon src={require('../assets/images/ShareIcon.png')} />
                    <CTAText>SHARE OUR<br />MISSION</CTAText>
                </CTAContainer>
            </CTAStrip>
            <SponsorStrip>THANK YOU TO OUR SPONSORS</SponsorStrip>
            <AdvertStrip>
                <AdvertCardContainer>
                    { sampleTiles.length > 0 ? sampleTiles.map((tile) => {
                        return (
                            <AdvertCard key={tile["title"]} href={tile["link"]} target={"_blank"}>
                                {tile.image ?
                                <img src={tile.image} /> 
                                :
                                <p>{tile.title}</p>
                                }
                            </AdvertCard>
                        );
                    }) : ""}
                </AdvertCardContainer>
                <ShareCount>{shareCount} Shares</ShareCount>
            </AdvertStrip>
            <MapStrip>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2301.687755290906!2d-1.3662101839909715!3d54.76787647498465!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487e8a72cab35f17%3A0xdecf85917518b044!2sFundraise%26Recycle%20Ltd!5e0!3m2!1sen!2suk!4v1679407659700!5m2!1sen!2suk" width={"100%"} height={620} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                <MapInfoBox>
                    <MapInfoContent>
                        <h2>Fundraise & Recycle</h2>
                        <h3>Location:</h3>
                        <p>17 Pease Rd,<br />North West Industrial Estate,<br />Peterlee SR8 2RD</p>
                        <h3 className="border-above">Phone:</h3>
                        <p>01917062199</p>
                        <h3 className="border-above">Email:</h3>
                        <p>admin@fundraiserecycleltd.co.uk</p>
                    </MapInfoContent>
                </MapInfoBox>
            </MapStrip>
            <Footer />
        </PageContainer>
    )
}

export default FundraiseRecycleBank

const ShareCount = styled.p`
    text-align: center;
    font-size: 30px;
    color: white;
    background: #BE3258;
    width: 100vw;
    margin: 0;
    padding: 48px 0 0 0;
`