import React from 'react'
import styled from 'styled-components'
import Header from '../components/Header'

const ThankYouSponsor = () => {
    return (
        <Container>
            <Header />
            <p>Thank you for sponsoring our clothes banks. You'll receive an email confirmation of your payment shortly.</p>
        </Container>
    )
}

export default ThankYouSponsor

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    >p {
        margin-bottom: 0;
    }
`