import { loadStripe } from '@stripe/stripe-js';

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe("pk_live_51OrieJRtiEQDWo97y2b2vzZ6adyLpnOcbaBxdGYXykx4gyo0Qdnf8IdUwHwmbt9uCYVcoQi0jr8wYOuPMhfVRCWH00BOOKF0xt");
  }
  return stripePromise;
};

export default getStripe;