import MailchimpSubscribe from "react-mailchimp-subscribe";
import React from 'react'
import styled from 'styled-components'

export const mailchimpUrl = `https://thefundraiseruk.us20.list-manage.com/subscribe/post?u=5fa8d6a007fc043c256420bcf&id=880b2ec32a`;
export const MailchimpForm = ({exitFunction, status, message, onValidated }) => {
    let first, last, email;
    const submit = () => {
        email &&
        first &&
        last &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            MERGE0: email.value,
            MERGE1: first.value,
            MERGE2: last.value,
        });
    }

    if (status === "success") {
        exitFunction(false)
    }

    return (
        <PopupContainer>
        <FormContainer>
        <h2>Subscribe for Updates</h2>
        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
        {status === "error" && (
            <div
            style={{ color: "red" }}
            dangerouslySetInnerHTML={{ __html: message }}
            />
        )}
        {status === "success" && (
            <div
            style={{ color: "green" }}
            dangerouslySetInnerHTML={{ __html: message }}
            />
        )}

        <input
            ref={node => (first = node)}
            type="text"
            placeholder="First name"
        />
        <br />
        <input
            ref={node => (last = node)}
            type="text"
            placeholder="Last name"
        />
        <br />
        <input
            ref={node => (email = node)}
            type="email"
            placeholder="Email Address"
        />
        <br />
        <button onClick={submit}>
            Submit
        </button>
        </FormContainer>
        </PopupContainer>
    );
}

export const MailchimpPopup = ({exitFunction}) => {
    return (
        <MailchimpSubscribe
            url={mailchimpUrl}
            render={({ subscribe, status, message }) => (
                <MailchimpForm
                exitFunction={exitFunction}
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
                />
            )}
        />
    )
}

const PopupContainer = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
`

const FormContainer = styled.div`
    background: white;
    margin: auto;
    border-radius: 18px;
    padding: 18px;
    display: flex;
    flex-direction: column;

    h2 {
        font-weight: 600;
        font-size: 36px;
    }

    input {
        margin: 4px 0;
        width: 100%;
        font-size: 1.5rem;
        padding: 8px;
        max-width: auto;
        width: auto;
    }
    
    button {
        max-width: 96px;
        font-size: 18px;
        padding: 8px;
        border: 0;
        background: #ECB525;
        color: white;
        font-weight: normal;
    }
`